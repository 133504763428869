import { AgentProfileFormValues } from '@dltru/dfa-models'
import { IRoleFormStepProps, IStepper, StepperC } from '@dltru/dfa-ui'
import { FC } from 'react'

import { BasicInformationStep, ConfirmingDocumentStep } from './steps'

export const ClientRepresentativeStepper: FC<
    Omit<IStepper<AgentProfileFormValues>, 'children'> & IRoleFormStepProps
> = ({ isEditAllow, ...props }) => (
    <StepperC {...props}>
        <BasicInformationStep isEditAllow={isEditAllow} />
        <ConfirmingDocumentStep isEditAllow={isEditAllow} />
    </StepperC>
)
