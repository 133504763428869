import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { getAnketaTagProps } from '@resources/anketaState'

import {
    HasBeneficiary,
    IndividualFormValues,
    ProfileStatus,
    ProfileType,
    editableProfileStatuses,
    individualForUpdateData,
    individualFrontToBack,
} from '@dltru/dfa-models'
import {
    Box,
    CopyButton,
    IFooter,
    PageHeader,
    Tag,
    checkBeneficialUuids,
    getFullName,
} from '@dltru/dfa-ui'

import { profileDetailsSlice } from '@store/profiles/details'
import { profileSelector } from '@store/profiles/details/selectors'
import { roleFormsSlice } from '@store/roleForms'
import { roleFormSelector } from '@store/roleForms/selectors'

import { countOfSteps } from '@components/Layout/RoleFormsLayout'

import { AlertForAnketa } from '../components/AlertForAnketa'
import { Footer } from '../components/Footer'

import { AnketaIndividualStepper } from './AnketaIndividualStepper'
import { disableFields } from './resources'

export const Individual: FC = () => {
    const reduxDispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const state = location.state as { isCreate: boolean } | undefined
    const isCreate = state?.isCreate
    const initialData = useSelector(roleFormSelector.selectIndividualFormValue)
    const profile = useSelector(roleFormSelector.selectIndividualAnketa)
    const approvalHistory = useSelector(profileSelector.selectApprovalHistory)
    const name = !isCreate && initialData ? getFullName(profile) : 'Физическое лицо'
    const [footerStatus, setFooterStatus] = useState<IFooter['status']>('initial')

    const onStepperFinish = (values: IndividualFormValues) => {
        if (isCreate) {
            reduxDispatch(roleFormsSlice.actions.clearOldProfile())
            reduxDispatch(
                roleFormsSlice.actions.updateIndividualAnketa(individualFrontToBack(values)),
            )
            setFooterStatus('ready-to-new')
        } else if (profile) {
            const dataForSend = individualForUpdateData(values, profile)
            reduxDispatch(roleFormsSlice.actions.putIndividualProfile(dataForSend))
        }
    }
    useEffect(() => {
        if (
            !isCreate &&
            (profile?.profile_status === ProfileStatus.REJECTED ||
                profile?.profile_status === ProfileStatus.REWORK) &&
            approvalHistory.length === 0
        ) {
            reduxDispatch(profileDetailsSlice.actions.getApprovalHistory())
        }
    }, [profile?.profile_status])

    useEffect(() => {
        if (initialData?.hasBeneficiary === HasBeneficiary.YES && profile?.beneficiaries) {
            reduxDispatch(roleFormsSlice.actions.getBeneficiaries(profile.beneficiaries))
        }
    }, [JSON.stringify(profile?.beneficiaries)])

    if (
        !isCreate &&
        (!initialData ||
            (initialData.hasBeneficiary === HasBeneficiary.YES &&
                !checkBeneficialUuids(profile?.beneficiaries, initialData.beneficiaries)))
    ) {
        return null
    }

    const tagProps =
        !isCreate && profile?.profile_status ? getAnketaTagProps(profile.profile_status) : undefined

    const isEditAllow =
        !initialData?.id ||
        (profile?.profile_status !== undefined &&
            editableProfileStatuses.includes(profile.profile_status))

    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title={`Анкета клиента: ${name}`}
                    tags={tagProps ? <Tag color={tagProps.color}>{tagProps.title}</Tag> : undefined}
                    onBack={() => navigate(-1)}
                    subTitle={
                        !isCreate && profile?.user_uuid ? (
                            <>
                                ID {profile.user_uuid}&ensp;
                                <CopyButton text={profile.user_uuid} />
                            </>
                        ) : undefined
                    }
                />
            </Box>
            {!isCreate && <AlertForAnketa approvalHistory={approvalHistory} />}
            <AnketaIndividualStepper
                initialValues={
                    (!isCreate || !initialData?.id) && initialData
                        ? initialData
                        : ({} as IndividualFormValues)
                }
                onStepperFinish={onStepperFinish}
                initialStep={!isCreate && initialData?.id ? countOfSteps[ProfileType.PRSN] + 1 : 0}
                isEditAllow={isEditAllow}
                disableFields={
                    profile?.profile_status === ProfileStatus.REWORK ? disableFields : undefined
                }
            />
            <Footer
                status={footerStatus}
                setFooterStatus={setFooterStatus}
                profileType={ProfileType.PRSN}
            />
        </>
    )
}
