import { BeneficialFormValues, HasBeneficiary, beneficiariesFrontToBack } from '@dltru/dfa-models'
import {
    Form,
    FormItem,
    IRoleFormStepProps,
    IValues,
    Radio,
    RadioChangeEvent,
    Space,
    Step,
    StepperContext,
    useEditable,
} from '@dltru/dfa-ui'
import { FC, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'

import { roleFormsSlice } from '@store/roleForms'

import { BeneficiariesBlock } from '../../components/BeneficiariesBlock'

const STEP_INDEX = 2

const beneficiaryOptions = {
    [HasBeneficiary.NO]: 'Я являюсь своим бенефициарным владельцем',
    [HasBeneficiary.YES]: 'У меня есть бенефициарные владельцы',
}

export const Beneficiary: FC<IRoleFormStepProps> = ({ isEditAllow }) => {
    const [form] = Form.useForm()
    const reduxDispatch = useDispatch()
    const { editable, stepsTotal, currentStep, values } = useContext(StepperContext)
    const [hasBeneficiary, setHasBeneficiary] = useState(
        values?.hasBeneficiary ?? HasBeneficiary.NO,
    )
    const isEdit = useEditable(currentStep, STEP_INDEX, editable)
    const allowEdit =
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(STEP_INDEX)) &&
        isEditAllow

    const listName = 'beneficiaries'
    const handleRadioChange = (e: RadioChangeEvent) => {
        const value = e.target.value
        setHasBeneficiary(value)
        form.setFieldsValue({ [listName]: value === HasBeneficiary.YES ? [{}] : [] })
    }

    const saveBeneficiaries = (values: IValues) => {
        if ((values as Record<typeof listName, BeneficialFormValues[]>)[listName]?.length) {
            reduxDispatch(
                roleFormsSlice.actions.updateBeneficiaryAnketa(
                    beneficiariesFrontToBack(
                        (values as Record<typeof listName, BeneficialFormValues[]>)[listName],
                    ),
                ),
            )
            reduxDispatch(roleFormsSlice.actions.sendBeneficiaryAnketa())
        }
    }

    return (
        <Step
            form={form}
            stepIndex={STEP_INDEX}
            stepTitle="Сведения о бенефициарных владельцах"
            allowEdit={Boolean(allowEdit)}
            saveAfterEdit
            makeSideEffect={!values?.id ? saveBeneficiaries : undefined}
        >
            <div className="formRow">
                <FormItem
                    className="formComponentItem--lg"
                    name="hasBeneficiary"
                    label=""
                    required
                    uneditable={isEdit}
                    renderUneditable={(value) => beneficiaryOptions[value] ?? value}
                >
                    <Radio.Group onChange={handleRadioChange}>
                        <Space direction="vertical">
                            {Object.entries(beneficiaryOptions).map(([key, value]) => (
                                <Radio key={key} value={key}>
                                    {value}
                                </Radio>
                            ))}
                        </Space>
                    </Radio.Group>
                </FormItem>
            </div>
            {hasBeneficiary === HasBeneficiary.YES && (
                <BeneficiariesBlock uneditable={isEdit} listName={listName} />
            )}
        </Step>
    )
}
