import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { getAnketaTagProps } from '@resources/anketaState'

import {
    AgentProfileFormValues,
    ProfileStatus,
    ProfileType,
    agentFrontToBack,
    editableProfileStatuses,
} from '@dltru/dfa-models'
import { Box, CopyButton, IFooter, PageHeader, Tag, getFullName } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { roleFormsSlice } from '@store/roleForms'
import { roleFormSelector } from '@store/roleForms/selectors'

import { countOfSteps } from '@components/Layout/RoleFormsLayout'

import { AlertForAnketa } from '../components/AlertForAnketa'
import { Footer } from '../components/Footer'

import { ClientRepresentativeStepper } from './ClientRepresantativeStepper'

export const ClientRepresentative: FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const state = location.state as { isCreate: boolean } | undefined
    const isCreate = state?.isCreate
    const userUuid = useSelector(authSelector.selectUserUid)
    const initialData = useSelector(roleFormSelector.selectAgentFormValue)
    const profile = useSelector(roleFormSelector.selectAgentAnketa)
    const approvalHistory = useSelector(roleFormSelector.selectApprovalHistoryAgent)
    const [footerStatus, setFooterStatus] = useState<IFooter['status']>('initial')
    const reduxDispatch = useDispatch()

    const onStepperFinish = (values: AgentProfileFormValues) => {
        if (isCreate) {
            reduxDispatch(roleFormsSlice.actions.clearOldProfile())
            reduxDispatch(roleFormsSlice.actions.updateAgentAnketa(agentFrontToBack(values)))
            setFooterStatus('ready-to-new')
        } else if (profile) {
            const newProfile = agentFrontToBack(values)
            reduxDispatch(
                roleFormsSlice.actions.putAgentProfile({
                    ...profile,
                    ...newProfile,
                    user_uuid: userUuid as string,
                }),
            )
        }
    }

    useEffect(() => {
        if (
            !isCreate &&
            (profile?.application_status === ProfileStatus.REJECTED ||
                profile?.application_status === ProfileStatus.REWORK) &&
            approvalHistory.length === 0
        ) {
            reduxDispatch(roleFormsSlice.actions.getApprovalHistoryAgent())
        }
    }, [profile?.application_status])

    if (!isCreate && !initialData) {
        return null
    }

    const tagProps =
        !isCreate && profile?.application_status
            ? getAnketaTagProps(profile.application_status)
            : undefined

    const isEditAllow =
        !initialData?.id ||
        (profile?.application_status !== undefined &&
            editableProfileStatuses.includes(profile.application_status))

    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title={`Анкета представителя${
                        !isCreate && initialData ? `: ${getFullName(profile)}` : ''
                    }`}
                    tags={tagProps ? <Tag color={tagProps.color}>{tagProps.title}</Tag> : undefined}
                    onBack={() => navigate(-1)}
                    subTitle={
                        !isCreate && profile?.uuid ? (
                            <>
                                ID {profile.uuid}&ensp;
                                <CopyButton text={profile.uuid} />
                            </>
                        ) : undefined
                    }
                />
            </Box>
            {!isCreate && <AlertForAnketa approvalHistory={approvalHistory} />}
            <ClientRepresentativeStepper
                initialValues={
                    (!isCreate || !initialData?.id) && initialData
                        ? initialData
                        : ({} as AgentProfileFormValues)
                }
                onStepperFinish={onStepperFinish}
                initialStep={!isCreate && initialData?.id ? countOfSteps[ProfileType.AGNT] + 1 : 0}
                isEditAllow={isEditAllow}
            />
            <Footer
                status={footerStatus}
                setFooterStatus={setFooterStatus}
                profileType={ProfileType.AGNT}
            />
        </>
    )
}
